// Percentual relativo - quando eu preciso saber qual a porcentagem de um valor em relação a um total
export const handleRelativePercentage = (
  totalValue: number,
  partialValue: number
): number => {
  if (totalValue === 0) {
    return 0;
  }

  return handleDivision(partialValue, totalValue) * 100;
};

export const handlePercentageDifference = (
  totalValue: number,
  partialValue: number,
  showWithStringFormat?: boolean,
  fixedDecimals: number = 2
): number | string => {
  if (totalValue === 0) {
    return 0;
  }

  const percentageValue =
    handleDivision(totalValue - partialValue, partialValue) * 100;

  if (showWithStringFormat) {
    return `${percentageValue.toFixed(fixedDecimals)}%`;
  }

  return percentageValue;
};

export const handleDivision = (numerator: number, denominator: number) => {
  if (!denominator || denominator === 0) {
    return 0;
  }

  return numerator / denominator;
};

export const handleNameInitials = (name: string): string => {
  let firstLetter = "";
  let secondLetter = "";

  if (!name || !name?.length) return "";

  const splitContent = name.split(" ");

  if (splitContent.length === 1) {
    return name.substring(0, 2);
  }

  if (splitContent[1].length > 1) {
    secondLetter = splitContent[1].substring(0, 1);
  } else {
    if (splitContent[2]) {
      secondLetter = splitContent[2].substring(0, 1);
    } else {
      secondLetter = splitContent[0].substring(1, 2);
    }
  }

  firstLetter = splitContent[0].substring(0, 1);

  return `${firstLetter}${secondLetter.toLowerCase()}`;
};

export const sanitizeStrings = (text: string): string => {
  if (!text) return "";

  return text.replace(/['"\n]/g, "");
};

export const normalizeString = (text: string): string => {
  if (!text) return "";

  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, "");
};
