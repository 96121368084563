import { cva, type VariantProps } from "class-variance-authority";
import { ChevronDown, XIcon } from "lucide-react";
import * as React from "react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { Badge, Button } from "../ui";
import { buttonVariants } from "../ui/Button/utils";
import { MultiSelectCommand } from "./MultiSelectCommand";
import { MultiSelectVirtualizedCommand } from "./MultiSelectVirtualizedCommand";
import { normalizeString } from "@/utils";

const multiSelectVariants = cva("m-1", {
  variants: {
    variant: {
      default: "border-foreground/10 text-foreground bg-card hover:bg-card/80",
      secondary:
        "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
      destructive:
        "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
      link: "text-primary underline-offset-4 hover:underline border-none",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface MultiSelectOptions {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
}

//@ts-expect-error erro que o maccari vai me ajudar depois
interface MultiSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  multiSelectVariant?: "default" | "secondary" | "destructive" | "link";
  options: MultiSelectOptions[];
  onValueChange: (value: any[]) => void;
  defaultValue: MultiSelectOptions[];
  hasInlineClear?: boolean;
  isVirtualized?: boolean;
  placeholder?: string;
  /**
   * Maximum number of items to display. Extra selected items will be summarized.
   * Optional, defaults to 3.
   */
  maxCount?: number;
  /**
   * The modality of the popover. When set to true, interaction with outside elements
   * will be disabled and only popover content will be visible to screen readers.
   * Optional, defaults to false.
   */
  modalPopover?: boolean;
  /**
   * Additional class names to apply custom styles to the multi-select component.
   * Optional, can be used to add custom styles.
   */
  className?: string;
}

export const MultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      onValueChange,
      multiSelectVariant,
      variant,
      defaultValue,
      placeholder = "Selecione as opções desejadas",
      hasInlineClear,
      maxCount = 3,
      modalPopover = true,
      isVirtualized = false,
      className,
      ...props
    },
    ref
  ) => {
    const [selectedOptions, setSelectedOptions] = React.useState<
      MultiSelectOptions[]
    >(defaultValue || []);

    const [filteredOptions, setFilteredOptions] =
      React.useState<MultiSelectOptions[]>(options);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedOptions];
        newSelectedValues.pop();
        setSelectedOptions(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option: MultiSelectOptions) => {
      const newSelectedValues = selectedOptions.find(
        (selectedOption) => selectedOption.value == option.value
      )
        ? selectedOptions.filter(
            (selectedOption) => selectedOption.value !== option.value
          )
        : [...selectedOptions, option];
      setSelectedOptions(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedOptions([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedOptions.slice(0, maxCount);
      setSelectedOptions(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleSearch = (search: string) => {
      const normalizedSearch = normalizeString(search);

      const filtered = options?.filter(
        (option) =>
          option?.label &&
          normalizeString(option?.label).includes(normalizedSearch),
      );

      setFilteredOptions(filtered);
    };

    const toggleAll = () => {
      const allFilteredSelected = filteredOptions.every((filteredOption) =>
        selectedOptions.some(
          (selectedOption) => selectedOption.value === filteredOption.value
        )
      );

      if (allFilteredSelected) {
        const remainingSelections = selectedOptions.filter(
          (selectedOption) =>
            !filteredOptions.some(
              (filteredOption) => filteredOption.value === selectedOption.value
            )
        );
        setSelectedOptions(remainingSelections);
        onValueChange(remainingSelections);
      } else {
        const updatedSelections = [
          ...selectedOptions,
          ...filteredOptions.filter(
            (filteredOption) =>
              !selectedOptions.some(
                (selectedOption) =>
                  selectedOption.value === filteredOption.value
              )
          ),
        ];
        setSelectedOptions(updatedSelections);
        onValueChange(updatedSelections);
      }
    };

    const handleSelectAllCheckIcon = React.useCallback(() => {
      if (filteredOptions.length === 0) return false;

      return filteredOptions.every((filteredOption) =>
        selectedOptions.some(
          (selectedOption) => selectedOption.value === filteredOption.value
        )
      );
    }, [filteredOptions, selectedOptions]);

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={() => {
          setIsPopoverOpen(!isPopoverOpen);
          handleSearch("");
        }}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            variant={variant}
            className={cn(
              "shadow-sm flex w-full p-1 h-auto min-h-11 items-center justify-between bg-inherit hover:bg-accent border fine-border text-accent-foreground hover:text-accent-foreground",
              className
            )}
          >
            {selectedOptions.length > 0 ? (
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-wrap items-center">
                  {selectedOptions.slice(0, maxCount).map((value) => {
                    const option = options.find((o) => o.value === value.value);
                    const IconComponent = option?.icon;
                    return (
                      <Badge
                        key={value.value}
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleOption(value);
                        }}
                        className={cn(
                          "font-normal hover:text-destructive hover:cursor-pointer",
                          multiSelectVariants({ multiSelectVariant } as any)
                        )}
                      >
                        {IconComponent && <IconComponent className="h-4 w-4" />}
                        {option?.label}
                        <XIcon className="ml-1 h-4 w-4" />
                      </Badge>
                    );
                  })}

                  {selectedOptions.length > maxCount && (
                    <Badge
                      className={cn(
                        "bg-transparent text-foreground border-foreground/1 hover:bg-transparent font-normal hover:text-destructive hover:cursor-pointer",
                        multiSelectVariants({ multiSelectVariant } as any)
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        clearExtraOptions();
                      }}
                    >
                      {`+ ${selectedOptions.length - maxCount} ${selectedOptions.length - maxCount > 1 ? "itens" : "item"}`}
                      <XIcon className="ml-1 h-4 w-4" />
                    </Badge>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  {hasInlineClear ? (
                    <>
                      <XIcon
                        className="h-4 mx-2 cursor-pointer text-muted-foreground"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClear();
                        }}
                      />
                      <Separator
                        orientation="vertical"
                        className="flex min-h-6 h-full"
                      />
                    </>
                  ) : null}
                  <ChevronDown className="h-4 mx-2 cursor-pointer text-muted-foreground" />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between w-full mx-auto">
                <span className="text-sm mx-3">{placeholder}</span>
                <ChevronDown className="h-4 cursor-pointer text-muted-foreground mx-2" />
              </div>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent
          className="popover-content-width-as-trigger-width p-0"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          {isVirtualized ? (
            <MultiSelectVirtualizedCommand
              filteredOptions={filteredOptions}
              handleSearch={handleSearch}
              handleInputKeyDown={handleInputKeyDown}
              toggleAll={toggleAll}
              handleSelectAllCheckIcon={handleSelectAllCheckIcon}
              selectedOptions={selectedOptions}
              toggleOption={toggleOption}
              handleClear={handleClear}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          ) : (
            <MultiSelectCommand
              filteredOptions={filteredOptions}
              handleSearch={handleSearch}
              handleInputKeyDown={handleInputKeyDown}
              toggleAll={toggleAll}
              handleSelectAllCheckIcon={handleSelectAllCheckIcon}
              selectedOptions={selectedOptions}
              toggleOption={toggleOption}
              handleClear={handleClear}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          )}
        </PopoverContent>
      </Popover>
    );
  }
);

MultiSelect.displayName = "MultiSelect";
