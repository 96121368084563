import * as React from "react";
import {
  CalendarDaysIcon,
  ChevronDown,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "lucide-react";
import {
  startOfMonth,
  endOfMonth,
  endOfDay,
  subMonths,
  isAfter,
} from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button, Command, CommandGroup, CommandInput, CommandItem } from "..";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ptBR } from "date-fns/locale";
import {
  CalendarDatePickerProps,
  dateRanges,
  months,
} from "@/constants/components/calendar-date-picker";
import { getDateWithTimezone } from "@/utils/intlDate";

const today = getDateWithTimezone(new Date());

export const CalendarDatePicker = React.forwardRef<
  HTMLButtonElement,
  CalendarDatePickerProps
>(
  (
    {
      id = "calendar-date-picker",
      className,
      date,
      closeOnSelect = false,
      isFormField = true,
      numberOfMonths = 2,
      yearsRange = 50,
      onDateSelect,
      variant = "outline",
      maxDate,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [internalDate, setInternalDate] = React.useState<DateRange>({
      from: date?.from || startOfMonth(today),
      to: date?.to || endOfMonth(today),
    });
    const [selectedRange, setSelectedRange] = React.useState<string | null>(
      numberOfMonths === 2 ? "Este ano" : "Hoje"
    );
    const [monthFrom, setMonthFrom] = React.useState<Date | undefined>(
      date?.from
    );
    const [yearFrom, setYearFrom] = React.useState<number | undefined>(
      date?.from?.getFullYear()
    );
    const [monthTo, setMonthTo] = React.useState<Date | undefined>(
      numberOfMonths === 2 ? date?.to : date?.from
    );
    const [yearTo, setYearTo] = React.useState<number | undefined>(
      numberOfMonths === 2 ? date?.to?.getFullYear() : date?.from?.getFullYear()
    );
    const [displayedMonth, setDisplayedMonth] = React.useState<
      Date | undefined
    >(date?.from || startOfMonth(today));

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleClose = () => setIsPopoverOpen(false);

    const selectDateRange = (from: Date, to: Date, range: string) => {
      if (maxDate && (isAfter(from, maxDate) || isAfter(to, maxDate))) {
        return;
      }
      const startDate = toDate(from, { timeZone });
      const endDate =
        numberOfMonths === 2 ? toDate(to, { timeZone }) : startDate;

      if (
        maxDate &&
        (isAfter(startDate, maxDate) || isAfter(endDate, maxDate))
      ) {
        return;
      }
      closeOnSelect && onDateSelect({ from: startDate, to: endDate });
      setInternalDate({ from: startDate, to: endDate });
      setSelectedRange(range);
      setMonthFrom(from);
      setYearFrom(from.getFullYear());
      setMonthTo(to);
      setYearTo(to.getFullYear());
      closeOnSelect && setIsPopoverOpen(false);
    };

    const years = Array.from(
      { length: yearsRange + 1 },
      (_, i) => today.getFullYear() - yearsRange / 2 + i
    );

    const handleDateSelect = (range: DateRange) => {
      if (!range || (!range.from && !range.to)) {
        if (date) {
          setInternalDate({ from: date?.from, to: date?.to });
        }
        return;
      }

      let from = toDate(range.from as Date, { timeZone });
      let to = range.to ? endOfDay(toDate(range.to, { timeZone })) : from;

      if (maxDate && (isAfter(from, maxDate) || isAfter(to, maxDate))) {
        setInternalDate({
          from: date?.from || startOfMonth(maxDate),
          to: date?.to || maxDate,
        });
        return;
      }

      if (numberOfMonths === 1) {
        if (range.from !== internalDate.from) {
          to = from;
        } else {
          from = toDate(range.to as Date, { timeZone });
        }
      }

      onDateSelect({ from, to });
      setInternalDate({ from, to });
      setMonthFrom(from);
      setYearFrom(from.getFullYear());
      setMonthTo(to);
      setYearTo(to.getFullYear());
      setSelectedRange(null);
    };

    const handleMonthChange = React.useCallback(
      (newMonthIndex: number, part: string) => {
        setSelectedRange(null);
        if (part === "from") {
          if (yearFrom !== undefined) {
            if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return;
            const newMonth = new Date(yearFrom, newMonthIndex, 1);
            const from =
              numberOfMonths === 2
                ? startOfMonth(toDate(newMonth, { timeZone }))
                : internalDate.from
                  ? new Date(
                      internalDate.from.getFullYear(),
                      newMonth.getMonth(),
                      internalDate.from.getDate()
                    )
                  : newMonth;
            const to =
              numberOfMonths === 2
                ? internalDate.to
                  ? endOfDay(toDate(internalDate.to, { timeZone }))
                  : endOfMonth(toDate(newMonth, { timeZone }))
                : from;
            if (from <= to) {
              setInternalDate({ from, to });
              setMonthFrom(newMonth);
              setDisplayedMonth(newMonth);
              setMonthTo(internalDate.to);
            }
          }
        } else {
          if (yearTo !== undefined) {
            if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return;
            const newMonth = new Date(yearTo, newMonthIndex, 1);
            const from = internalDate.from
              ? toDate(internalDate.from, { timeZone })
              : startOfMonth(toDate(newMonth, { timeZone }));
            const to =
              numberOfMonths === 2
                ? endOfMonth(toDate(newMonth, { timeZone }))
                : from;
            if (from <= to) {
              setInternalDate({ from, to });
              setMonthTo(newMonth);
              setDisplayedMonth(subMonths(newMonth, 1));
              setMonthFrom(internalDate.from);
            }
          }
        }
      },
      [
        internalDate.from,
        internalDate.to,
        numberOfMonths,
        timeZone,
        yearFrom,
        yearTo,
        yearsRange,
      ]
    );

    const handleYearChange = React.useCallback(
      (newYear: number, part: string) => {
        setSelectedRange(null);
        if (part === "from") {
          const newMonth = monthFrom
            ? new Date(newYear, monthFrom ? monthFrom.getMonth() : 0, 1)
            : new Date(newYear, 0, 1);
          const from =
            numberOfMonths === 2
              ? startOfMonth(toDate(newMonth, { timeZone }))
              : internalDate.from
                ? new Date(
                    newYear,
                    newMonth.getMonth(),
                    internalDate.from.getDate()
                  )
                : newMonth;
          const to =
            numberOfMonths === 2
              ? internalDate.to
                ? endOfDay(toDate(internalDate.to, { timeZone }))
                : endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            setInternalDate({ from, to });
            setYearFrom(newYear);
            setMonthFrom(newMonth);
            setDisplayedMonth(newMonth);
            setYearTo(internalDate.to?.getFullYear());
            setMonthTo(internalDate.to);
          }
        } else {
          const newMonth = monthTo
            ? new Date(newYear, monthTo.getMonth(), 1)
            : new Date(newYear, 0, 1);
          const from = internalDate.from
            ? toDate(internalDate.from, { timeZone })
            : startOfMonth(toDate(newMonth, { timeZone }));
          const to =
            numberOfMonths === 2
              ? endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            setInternalDate({ from, to });
            setYearTo(newYear);
            setMonthTo(newMonth);
            setDisplayedMonth(subMonths(newMonth, 1));
            setYearFrom(internalDate.from?.getFullYear());
            setMonthFrom(internalDate.from);
          }
        }
      },
      [
        internalDate.from,
        internalDate.to,
        monthFrom,
        monthTo,
        numberOfMonths,
        timeZone,
      ]
    );

    const formatWithTz = (date: Date, fmt: string) =>
      formatInTimeZone(date, timeZone, fmt, { locale: ptBR });

    React.useEffect(() => {
      if (date) {
        setInternalDate({
          from: date?.from,
          to: date?.to,
        });
      }
    }, [date]);

    return (
      <>
        <Popover
          open={isPopoverOpen}
          onOpenChange={() => {
            setIsPopoverOpen(!isPopoverOpen);
            handleDateSelect(internalDate);
          }}
        >
          <PopoverTrigger
            asChild
            className={cn("w-full h-11", !isFormField && "h-9")}
          >
            <Button
              id="date"
              ref={ref}
              {...props}
              className={cn(
                "w-full justify-between",
                className,
                !isFormField &&
                  "w-auto flex text-sm font-semibold text-zinc-600"
              )}
              variant={variant}
            >
              <div className="flex items-center">
                {!isFormField ? (
                  <CalendarDaysIcon className="mr-2 h-4 w-4" />
                ) : null}
                <span>
                  {internalDate?.from ? (
                    internalDate.to &&
                    formatWithTz(internalDate.from, "dd/MM/yyyy") !==
                      formatWithTz(internalDate.to, "dd/MM/yyyy") ? (
                      <>
                        <span id={`firstDay-${id}`}>
                          {formatWithTz(internalDate.from, "dd")}
                        </span>
                        {" de "}
                        <span id={`firstMonth-${id}`}>
                          {formatWithTz(
                            internalDate.from,
                            window.innerWidth > 420 ? "LLLL" : "LLL"
                          )}
                        </span>
                        {" de "}
                        <span id={`firstYear-${id}`}>
                          {formatWithTz(internalDate.from, "y")}
                        </span>
                        {numberOfMonths === 2 && (
                          <>
                            {" - "}
                            <span id={`secondDay-${id}`}>
                              {formatWithTz(internalDate.to, "dd")}
                            </span>
                            {" de "}
                            <span id={`secondMonth-${id}`}>
                              {formatWithTz(
                                internalDate.to,
                                window.innerWidth > 420 ? "LLLL" : "LLL"
                              )}
                            </span>
                            {" de "}
                            <span id={`secondYear-${id}`}>
                              {formatWithTz(internalDate.to, "y")}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span id="day">
                          {formatWithTz(internalDate.from, "dd")}
                        </span>
                        {" de "}
                        <span id="month">
                          {formatWithTz(internalDate.from, "LLLL")}
                        </span>
                        {" de "}
                        <span id="year">
                          {formatWithTz(internalDate.from, "y")}
                        </span>
                      </>
                    )
                  ) : (
                    <span>Selecione uma data</span>
                  )}
                </span>
              </div>
              {isFormField ? (
                <ChevronDownIcon className="h-4 w-4 opacity-50 shrink-0" />
              ) : null}
            </Button>
          </PopoverTrigger>
          {isPopoverOpen && (
            <PopoverContent
              className="w-auto"
              onInteractOutside={handleClose}
              onEscapeKeyDown={handleClose}
              style={{
                maxHeight: "var(--radix-popover-content-available-height)",
                overflowY: "auto",
              }}
            >
              <div className="flex max-[420px]:flex-col">
                {numberOfMonths === 2 && (
                  <div className="flex flex-col gap-1 pr-4 text-left max-[420px]:grid max-[420px]:grid-cols-2 max-[420px]:pb-4 min-[420px]:border-r border-foreground/10">
                    {dateRanges.map(({ label, start, end }) => (
                      <Button
                        key={label}
                        variant="ghost"
                        size="sm"
                        className={cn(
                          "justify-start hover:bg-brand-blue-500 hover:text-background",
                          selectedRange === label &&
                            "bg-brand-blue-500 text-background hover:bg-brand-blue-700 hover:text-background"
                        )}
                        onClick={() => {
                          selectDateRange(start, end, label);
                          setMonthFrom(start);
                          setDisplayedMonth(start);
                          setYearFrom(start.getFullYear());
                          setMonthTo(end);
                          setYearTo(end.getFullYear());
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                )}
                <div className="flex flex-col">
                  <div className="flex items-center gap-4 justify-between max-sm:flex-col">
                    <div className="flex gap-2 ml-3">
                      <Button
                        variant={"outline"}
                        className="px-2"
                        onClick={() => {
                          setDisplayedMonth(internalDate.from);
                        }}
                      >
                        <ChevronLeftIcon
                          strokeWidth={1.5}
                          className="text-muted-foreground w-4 h-4"
                        />
                      </Button>
                      <Select
                        onValueChange={(value) => {
                          handleMonthChange(months.indexOf(value), "from");
                          setSelectedRange(null);
                        }}
                        value={
                          internalDate?.from
                            ? months[internalDate.from.getMonth()]
                            : undefined
                        }
                      >
                        <SelectTrigger className="w-auto focus:ring-0 focus:ring-offset-0 font-medium hover:bg-accent hover:text-accent-foreground">
                          <SelectValue placeholder="Mês" />
                        </SelectTrigger>
                        <SelectContent>
                          {months.map((month, idx) => (
                            <SelectItem key={idx} value={month}>
                              {month}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Popover>
                        <PopoverTrigger className="w-20 h-9">
                          <Button
                            type="button"
                            variant="outline"
                            className="justify-between w-20"
                          >
                            <p>{yearFrom || "Ano"}</p>
                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="p-0 w-24">
                          <Command
                            value={yearFrom ? yearFrom.toString() : undefined}
                          >
                            <CommandInput placeholder={"Ano"} />
                            <CommandGroup className="max-h-64 w-24 overflow-auto">
                              {years.map((year) => (
                                <CommandItem
                                  key={year}
                                  value={year.toString()}
                                  onSelect={(value) => {
                                    handleYearChange(Number(value), "from");
                                    setSelectedRange(null);
                                  }}
                                  className="inset-y-0 w-20 justify-center"
                                >
                                  {year}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                    {numberOfMonths === 2 && (
                      <div className="flex gap-2">
                        <Select
                          onValueChange={(value) => {
                            handleMonthChange(months.indexOf(value), "to");
                            setSelectedRange(null);
                          }}
                          value={
                            internalDate.to
                              ? months[internalDate.to.getMonth()]
                              : undefined
                          }
                        >
                          <SelectTrigger className="w-auto focus:ring-0 focus:ring-offset-0 font-medium hover:bg-accent hover:text-accent-foreground">
                            <SelectValue placeholder="Mês" />
                          </SelectTrigger>
                          <SelectContent>
                            {months.map((month, idx) => (
                              <SelectItem key={idx} value={month}>
                                {month}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <Popover>
                          <PopoverTrigger className="w-20 h-9">
                            <Button
                              type="button"
                              variant="outline"
                              className="justify-between w-20"
                            >
                              <p>{yearTo || "Ano"}</p>
                              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="p-0 w-24">
                            <Command
                              value={yearTo ? yearTo.toString() : undefined}
                            >
                              <CommandInput placeholder={"Ano"} />
                              <CommandGroup className="max-h-64 w-24 overflow-auto">
                                {years.map((year) => (
                                  <CommandItem
                                    key={year}
                                    value={year.toString()}
                                    onSelect={(value) => {
                                      handleYearChange(Number(value), "to");
                                      setSelectedRange(null);
                                    }}
                                    className="inset-y-0 w-20 justify-center"
                                  >
                                    {year}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                        <Button
                          variant={"outline"}
                          className="px-2"
                          onClick={() => {
                            if (internalDate.to) {
                              setDisplayedMonth(subMonths(internalDate.to, 1));
                            }
                          }}
                        >
                          <ChevronRightIcon
                            strokeWidth={1.5}
                            className="text-muted-foreground w-4 h-4"
                          />
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    <Calendar
                      mode="range"
                      defaultMonth={monthFrom}
                      month={displayedMonth}
                      onMonthChange={setDisplayedMonth}
                      locale={ptBR}
                      selected={internalDate}
                      onSelect={(value) => {
                        setYearFrom(value?.from?.getFullYear());
                        setMonthFrom(value?.from);
                        setYearTo(value?.to?.getFullYear());
                        setMonthTo(value?.to);

                        if (selectedRange) setSelectedRange(null);
                        setInternalDate({ from: value?.from, to: value?.to });
                      }}
                      numberOfMonths={numberOfMonths}
                      className={className}
                    />
                  </div>
                </div>
              </div>
              {!isFormField ? (
                <div className="flex justify-end">
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      handleDateSelect(internalDate);
                      setIsPopoverOpen(!isPopoverOpen);
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
              ) : null}
            </PopoverContent>
          )}
        </Popover>
      </>
    );
  }
);
